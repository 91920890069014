import Vue from 'vue';

const dictionary = {
  ru: {
    // placeholders
    choose: 'Выберите',
    amount: 'Введите сумму',
    email: 'Введите почту',
    phone: 'Введите номер телефона',
    tg: 'Введите телеграмм',
    names: 'Введите ФИО',
    wallet: 'Введите кошелёк',
    sbpphone: 'Введите номер СБП',
    card: 'Введите номер карты',
    bank: 'Введите название банка',
    bankAccountNumber: 'Введите номер счёта',
    contactInfo: 'Введите телефон / телеграмм',

    // pay form 
    send: 'Отдаёте',
    get: 'Получаете',
    contactData: 'Контактные данные',
    withdrawalData: 'Данные для вывода',
    amlAgree: 'Соглашаюсь с политикой ',
    reserve: 'Резерв',
    createOrderBtn: 'Обменять',
    refetchRate: 'Обновляем курсы',
    chooseDirection: 'Выберите валюту для получения',
    fillField: 'Заполните поле',

    //shit
    banks: 'Банки',
    cryptocurrency: 'Криптовалюты',
    fiat: 'Наличные',

    name: 'Имя',
    mail: 'Почта',
    phoneNumber: 'Телефон',
    orderAmount: 'Объём заявок',
    history: 'История',
    refProgram: 'Реф. программа',
    refProgramText1: 'Получайте ',
    refProgramText2: '% от объёма всех обменов ваших рефералов, вы можете пригласить неограниченое число рефералов по своей ссылке и они закрекпляются за вами навсегда. Реферальный бонус начисляется в USDT, вывод возможен от 100 USDT.',
    refBallance: 'Доступно',
    generateRefCode: 'Создать код',
    enterTRC20address: 'Введите адрес TRC20',
    enterAmount: 'Введите сумму',
    withdrawalAll: 'Вывести всё',
    refFrozen: 'На выводе',
    withdraw: 'Вывести',
    withdrawals: 'Выводы',
    address: 'Адрес',
    amountTitle: 'Сумма',
    status: 'Статус',
    cards: 'Карты',
    bankTitle: 'Банк',
    number: 'Номер',
    settings: 'Настройки',
    changePassword: 'Изменить пароль',
    editProfile: 'Редактировать профиль',
    addCard: 'Добавить карту',
    deleteAccount: 'Удалить аккаунт',
    enterNewPassword: 'Введите новый пароль',
    enterNewPasswordAgain: 'Введите новый пароль повторно',
    enterOldPassword: 'Введите старый пароль',
    cancel: 'Отмена',
    save: 'Сохранить',
    enterCardNumber: 'Введите номер карты',
    enterBankName: 'Введите название банка',
    enterFullName: 'Введите ФИО',
    loadCardPhoto: 'Загрузить фото карты',
    deleteAccountText: 'Для удаления вашего аккаунта и всей информации связаной с ним введите в поле слово - BITCOIN',
    enterWord: 'Введите слово',
    delete: 'Удалить',
    enterEmail: 'Введите почту',
    enterPhone: 'Введите телефон',
    enterName: 'Введите имя',
    enterSurname: 'Введите фамилию',
    noExchange: 'Нет обменов',
    created: 'Создана',
    sended: 'Отдали',
    geted: 'Получили',

    NEW: 'Новый',
    PENDING: 'В обработке',
    PAID: 'Поступила оплата',
    COMPLETED: 'Завершен',
    TIMEEND: 'Истекло время',
    AMLRISK: 'AML риск',
    CANCELED: 'Отменена',
    ERROR: 'Ошибка',
    PARTIALLYPAID: 'Оплачен частично',
    account: 'Аккаунт',
    noHistory: 'Нет истории',
    noCards: 'Нет карт',

    exchange: 'Обменять',
    feedback: 'Отзывы',
    services: 'Услуги',
    news: 'Новости',
    rules: 'Правила',
    aml: 'KYC / AML',
    contact: 'Контакты',
    partnerProgram: 'Партнёрская программа',
    rates: 'Тарифы',
    faq: 'FAQ',
    chart: 'Графики',

    offers: 'Оферты',
    login: 'Войти',
    markets: 'Рынки',
    orders: 'Заявки',
    wallets: 'Кошельки',
    partners: 'Партнёры',
    redirects: 'Переходы',
    statistics: 'Статистика',
    users: 'Пользователи',
    pages: 'Страницы',
    blockLists: 'Черный список',
    logout: 'Выйти',

    registration: 'Регистрация',
    passwordAgain: 'Повторите пароль',
    enterPassword: 'Введите пароль',
    password: 'Пароль',
    enterPasswordAgain: 'Введите пароль повторно',
    refCode: 'Реферальный код',
    enterRefCode: 'Введите реферальный код',
    signupAgree1: 'Я согласен с',
    signupAgree2: 'условиями и правилами сервиса',
    signUp: 'Зарегистрироваться',
    isAccount: 'Уже есть аккаунт?',

    noAccount: 'Ещё нет аккаунта?',
    forgotPassword: 'Забыли пароль?',
    chooseAccountType: 'Выберите тип аккаунта',
    user: 'Пользователь',
    monitoring: 'Мониторинг',
    partner: 'Партнёрская программа',
    partnerTitle: 'Партнёр',
    restorePassword: 'Восстановление пароля',
    restore: 'Восстановить',
    getRestoreInstruction: 'Получить инструкцию для входа',

    partnerRegistration: 'Регистрация Партнёра',

    commission: 'Комиссия',
    balance: 'Баланс',
    link: 'Ссылка',
    ratesLink: 'Ссылка на курсы',
    createWithdrawalRequest: 'Создать заявку на вывод',
    withdrawalHistory: 'История вывода',
    noWithdrawalHistory: 'Нет истории выводов',
    title404: 'Ничего не нашли, пора домой',
    goHome: 'Домой',
    commissionHistory: 'История отчислений',

    date: 'Дата',
    profit: 'Маржа',
    orderPageMetaTitle: 'Заявка',

    orderLoginFormTitle: 'Аккаунт с этой почтой существует, пожалуйста войдите в свой аккаунт чтобы добавить карту',
    orderRateTitle: 'Курс обмена',
    orderCreateTitle: 'Заявка создана',
    orderPayoutTitle: 'Счёт получателя',
    orderEmailTitle: 'Контактная почта пользователя',
    orderPhoneTitle: 'Контактный номер пользователя',
    orderUserContactDataTitle: 'Контактный данные пользователя',
    orderCardTitle: 'Карта для оплаты',
    orderCardNumberTitle: 'Номер карты',
    orderNameTitle: 'ФИО получателя',
    orderChooseCardTitle: 'Выбор карты',
    orderWaitForCardTitle: 'Получаем реквизиты для оплаты, это займёт несколько мгновений',
    orderChoose: 'Выбрать',
    cancelOrder: 'Отменить заявку',
    iPaid: 'Я оплатил',
    refreshOrder: 'Обновить заявку',
    contactManadger: 'Связь с менеджером',
    writeFeedback: 'Введите отзыв о нашей работе...',
    loadMore: 'Увеличить',
    cookieTitle: 'Мы используем cookie.',
    okay: 'Хорошо',
    leaveFeedback: 'Оставить отзыв',
    thanksFeedback: 'Спасибо за отзыв',
  },
  en: {
    choose: 'Choose',
    amount: 'Enter amount',
    email: 'Enter email',
    phone: 'Enter phone number',
    tg: 'Enter telegram',
    names: 'Enter names',
    wallet: 'Enter wallet',
    sbpphone: 'Enter SBP phone',
    card: 'Enter card number',
    bank: 'Enter bank name',
    bankAccountNumber: 'Enter bank account number',
    contactInfo: 'Enter phone / telegram',

    // pay form 
    send: 'Send',
    get: 'Receive',
    contactData: 'Contact info',
    withdrawalData: 'Withdrawal info',
    amlAgree: 'I agree with ',
    reserve: 'Reserve',
    createOrderBtn: 'Exchange',
    refetchRate: 'We update exchange rates',
    chooseDirection: 'Select the currency to receive',
    fillField: 'Fill in the field',

    //shit
    banks: 'Banks',
    cryptocurrency: 'Cryptocurrencies',
    fiat: 'Cash',

    name: 'Name',
    mail: 'Email',
    phoneNumber: 'Phone',
    orderAmount: 'Order amount',
    history: 'History',
    refProgram: 'Ref. program',
    refProgramText1: 'Get ',
    refProgramText2: '% depending on the volume of all exchanges of your referrals, you can invite an unlimited number of referrals using your link and they will be assigned to you forever. The referral bonus is credited in USDT, withdrawal is possible from 100 USDT.',
    refBallance: 'Available',
    generateRefCode: 'Generate code',
    enterTRC20address: 'Enter address TRC20',
    enterAmount: 'Enter amount',
    withdrawalAll: 'Withdraw all funds',
    refFrozen: 'On withdrawal',
    withdraw: 'Withdraw',
    withdrawals: 'Withdraws',
    address: 'Address',
    amountTitle: 'Amount',
    status: 'Status',
    cards: 'Cards',
    bankTitle: 'Bank',
    number: 'Number',
    settings: 'Settings',
    changePassword: 'Change password',
    editProfile: 'Edit profile',
    addCard: 'Add card',
    deleteAccount: 'Delete account',
    enterNewPassword: 'Enter new password',
    enterNewPasswordAgain: 'Enter new password again',
    enterOldPassword: 'Enter old password',
    cancel: 'Cancel',
    save: 'Save',
    enterCardNumber: 'Enter card number',
    enterBankName: 'Enter bank name',
    enterFullName: 'Enter full name',
    loadCardPhoto: 'Load card photo',
    deleteAccountText: 'To delete your account and all information associated with it, enter the word - BITCOIN',
    enterWord: 'Enter word',
    delete: 'Delete',
    enterEmail: 'Enter email',
    enterPhone: 'Enter phone',
    enterName: 'Enter name',
    enterSurname: 'Enter surname',
    noExchange: 'No exchanges',
    created: 'Created',
    sended: 'Sent',
    geted: 'Got',

    NEW: 'New',
    PENDING: 'Pending',
    PAID: 'Paid',
    COMPLETED: 'Completed',
    TIMEEND: 'Time end',
    AMLRISK: 'AML risk',
    CANCELED: 'Canceled',
    ERROR: 'Error',
    PARTIALLYPAID: 'Partially paid',
    account: 'Account',
    noHistory: 'No history',
    noCards: 'No cards',

    exchange: 'Exchange',
    feedback: 'Feedbacks',
    services: 'Services',
    news: 'News',
    offers: 'Offers',
    rules: 'Rules',
    aml: 'KYC / AML',
    contact: 'Contacts',
    partnerProgram: 'Partner program',
    rates: 'Rates',
    faq: 'FAQ',
    chart: 'Charts',
    login: 'Login',
    markets: 'Markets',
    orders: 'Orders',
    wallets: 'Wallets',
    partners: 'Partners',
    redirects: 'Redirects',
    statistics: 'Statistics',
    users: 'Users',
    pages: 'Pages',
    blockLists: 'Black list',
    logout: 'Logout',

    registration: 'Registration',
    passwordAgain: 'Repeat password',
    enterPassword: 'Enter password',
    password: 'Password',
    enterPasswordAgain: 'Enter password again',
    refCode: 'Referral code',
    enterRefCode: 'Enter your referral code',
    signupAgree1: 'I agree with',
    signupAgree2: 'terms and conditions of service',
    signUp: 'Register',
    isAccount: 'Already have an account?',

    noAccount: 'Don`t have an account yet?',
    forgotPassword: 'Forgot your password?',
    chooseAccountType: 'Select account type',
    user: 'User',
    monitoring: 'Monitoring',
    partner: 'Partner program',
    partnerTitle: 'Partner',
    restorePassword: 'Password recovery',
    restore: 'Restore',
    getRestoreInstruction: 'Get login instructions',

    partnerRegistration: 'Partner Registration',

    commission: 'Commission',
    balance: 'Balance',
    link: 'Link',
    ratesLink: 'Rates link',
    createWithdrawalRequest: 'Create a withdrawal request',
    withdrawalHistory: 'Withdrawal history',
    noWithdrawalHistory: 'No withdrawal history',
    title404: 'We didn`t find anything, it`s time to go home',
    goHome: 'Home',
    commissionHistory: 'Commission history',

    date: 'Date',
    profit: 'Profit',
    orderPageMetaTitle: 'Order',

    orderLoginFormTitle: 'An account with this email exists, please log in to your account to add a card',
    orderRateTitle: 'Exchange rate',
    orderCreateTitle: 'Order created',
    orderPayoutTitle: 'Recipient`s account',
    orderEmailTitle: 'User contact email',
    orderPhoneTitle: 'User contact phone',
    orderUserContactDataTitle: 'User contact information',
    orderCardTitle: 'Card for payment',
    orderCardNumberTitle: 'Card number',
    orderNameTitle: 'Recipient`s name',
    orderChooseCardTitle: 'Selecting a card',
    orderWaitForCardTitle: 'We receive payment details, it will take a few moments',
    orderChoose: 'Choose',
    cancelOrder: 'Cancel order',
    iPaid: 'I paid',
    refreshOrder: 'Update order',
    contactManadger: 'Contact the manager',
    writeFeedback: 'Enter your review of our work...',
    loadMore: 'Load more',
    cookieTitle: 'We use cookies.',
    okay: 'Ok',
    leaveFeedback: 'Leave feedback',
    thanksFeedback: 'Thanks for your feedback',
  }
};

function getLanguageFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
  return match ? match[2] : 'ru'; // Если cookie не найдены, по умолчанию используем 'ru'
}

// Установим локаль на основе cookie
const language = getLanguageFromCookie();
Vue.prototype.$dictionary = dictionary[language];

export default dictionary;