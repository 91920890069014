const metaPublic = { layout: 'LayoutEmpty' }
const metaAccount = { layout: 'LayoutDefault' }
export default [
  {
    path: '/maintenance',
    name: 'page-maintenance',
    component: () => import('@/pages/maintenance'),
    meta: metaPublic
  },

  {
    path: '/admin-users',
    name: 'page-admin-users',
    component: () => import('@/pages/admin-users'),
    meta: metaAccount
  },
  {
    path: '/admin-orders',
    name: 'page-admin-orders',
    component: () => import('@/pages/admin-orders'),
    meta: metaAccount
  },
  {
    path: '/admin-rates',
    name: 'page-admin-rates',
    component: () => import('@/pages/admin-rates'),
    meta: metaAccount
  },
  {
    path: '/admin-wallets',
    name: 'page-admin-wallets',
    component: () => import('@/pages/admin-wallets'),
    meta: metaAccount
  },
  {
    path: '/admin-markets',
    name: 'page-admin-markets',
    component: () => import('@/pages/admin-markets'),
    meta: metaAccount
  },
  {
    path: '/admin-partners',
    name: 'page-admin-partners',
    component: () => import('@/pages/admin-partners'),
    meta: metaAccount
  },
  {
    path: '/admin-redirects',
    name: 'page-admin-redirects',
    component: () => import('@/pages/admin-redirects'),
    meta: metaAccount
  },
  {
    path: '/admin-cards',
    name: 'page-admin-cards',
    component: () => import('@/pages/admin-cards'),
    meta: metaAccount
  },
  {
    path: '/admin-pages',
    name: 'page-admin-pages',
    component: () => import('@/pages/admin-pages'),
    meta: metaAccount
  },
  {
    path: '/admin-statistics',
    name: 'page-admin-statistics',
    component: () => import('@/pages/admin-statistics'),
    meta: metaAccount
  },
  {
    path: '/admin-bans',
    name: 'page-admin-bans',
    component: () => import('@/pages/admin-bans'),
    meta: metaAccount
  },

  {
    path: '/partners-signup',
    name: 'page-partners-signup',
    component: () => import('@/pages/partners-signup'),
    meta: metaAccount
  },

  {
    path: '/',
    name: 'page-main',
    component: () => import('@/pages/main'),
    meta: metaAccount
  },
  {
    path: '/rates',
    name: 'page-rates',
    component: () => import('@/pages/rates'),
    meta: metaAccount
  },
  {
    path: '/news',
    name: 'page-news',
    component: () => import('@/pages/news'),
    meta: metaAccount
  },
  {
    path: '/partner',
    name: 'page-partner',
    component: () => import('@/pages/partner'),
    meta: metaAccount
  },
  {
    path: '/feedback',
    name: 'page-feedback',
    component: () => import('@/pages/feedback'),
    meta: metaAccount
  },
  {
    path: '/account',
    name: 'page-account',
    component: () => import('@/pages/account'),
    meta: metaAccount
  },
  {
    path: '/account-monitoring',
    name: 'page-account-monitoring',
    component: () => import('@/pages/account-monitoring'),
    meta: metaAccount
  },
  {
    path: '/order/:id',
    name: 'page-order',
    component: () => import('@/pages/order'),
    meta: metaAccount
  },
  {
    path: '/rules',
    name: 'page-rules',
    component: () => import('@/pages/rules'),
    meta: metaAccount
  },
  {
    path: '/aml',
    name: 'page-aml',
    component: () => import('@/pages/aml'),
    meta: metaAccount
  },
  {
    path: '/services',
    name: 'page-services',
    component: () => import('@/pages/services'),
    meta: metaAccount
  },
  {
    path: '/faq',
    name: 'page-faq',
    component: () => import('@/pages/faq'),
    meta: metaAccount
  },
  {
    path: '/contact',
    name: 'page-contact',
    component: () => import('@/pages/contact'),
    meta: metaAccount
  },
  {
    path: '/login',
    name: 'page-login',
    component: () => import('@/pages/login'),
    meta: metaAccount
  },
  {
    path: '/signup',
    name: 'page-signup',
    component: () => import('@/pages/signup'),
    meta: metaAccount
  },
  {
    path: '/chart',
    name: 'page-chart',
    component: () => import('@/pages/chart'),
    meta: metaAccount
  },
  {
    path: '/*',
    name: 'page-404',
    component: () => import('@/pages/404'),
    meta: metaPublic
  },
]
