import Vue from 'vue'

export default {
  state: {
    feedbacks: {
      items: [],
      count: null
    },
    news: [],
    params: null,
    pages: {},
    components: {}
  },
  mutations: {
    setFeedbacks (state, feedbacks) {
      Vue.set(state, 'feedbacks', feedbacks)
    },
    setNews (state, news) {
      Vue.set(state, 'news', news)
    },
    setGlobal (state, params) {
      Vue.set(state, 'params', params.global)
      Vue.set(state, 'pages', params.pages.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
      Vue.set(state, 'components', params.components.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
    },
  },
  actions: {
    async updateMaintenance (ctx) {
      const res = await Vue.$api.updateMaintenance()
      ctx.commit('setGlobal', res?.data)
      return res?.data
    },
    async globalParams (ctx, params) {
      const res = await Vue.$api.globalParams({ segments: { lang: params } })
      ctx.commit('setGlobal', res?.data)
      return res?.data
    },
    async setRedirect (ctx, params) {
      const res = await Vue.$api.setRedirect({ data: params })
      return res.data
    },
    async fetchFeedbacks (ctx, params) {
      const res = await Vue.$api.fetchFeedbacks({ params })
      ctx.commit('setFeedbacks', res?.data)
    },
    async fetchNews (ctx) {
      const res = await Vue.$api.fetchNews()
      ctx.commit('setNews', res?.data.articles)
    },
    async addCardMutation (ctx, params) {
      const res = await Vue.$api.addCardMutation({ data: params })
      return res?.data
    }
  }
}
