import Vue from 'vue';

const messages = {
  ru: {
    UNKNOWN_ERROR: 'Неизвестная ошибка',
    IMAGE_UPDATED: 'Фото загружено',
    ACCOUNT_DELETED: 'Аккаунт удалён',
    ACCOUNT_UPDATED: 'Аккаунт обновлён',
    PASSWORD_UPDATED: 'Пароль обновлён',
    SUCCESS_LOGIN: 'Успешный вход',
    ORDER_GET_ERROR: 'Ошибка загрузки заявки',
    ORDER_CANCELED_SUCCESS: 'Заявка отменена',
    ORDER_CANCELED_ERROR: 'Ошибка отмены заявки',
    ORDER_UPDATE_ERROR: 'Ошибка обновления заявки',
    ORDER_UPDATE_SUCCESS: 'Заявка обновлена',
    SUCCESS_SIGNUP: 'Регистрация успешна',
    COPIED: 'Скопировано',
    NEEDWORKER: 'Нужно принять заявку',
    RESET_PASSWORD_ERROR: 'Ошибка сброса пароля',
    CREATE_CARD_ERROR: 'Ошибка создания карты',
    UPDATE_CARD_ERROR: 'Ошибка обновления карты',
    DELETE_CARD_ERROR: 'Ошибка удаления карты',
    CREATE_COIN_ERROR: 'Ошибка создания монеты',
    UPDATE_COIN_ERROR: 'Ошибка обновления монеты',
    DELETE_COIN_ERROR: 'Ошибка удаления монеты',
    GER_COMPONENTS_ERROR: 'Ошибка получения компонент',
    COMPONENT_CREATE_ERROR: 'Ошибка создания компоненты',
    COMPONENT_UPDATE_ERROR: 'Ошибка обновления компоненты',
    COMPONENT_DELETE_ERROR: 'Ошибка удаления компоненты',
    CREATE_MARKET_ERROR: 'Ошибка создания рынка',
    UPDATE_MARKET_ERROR: 'Ошибка обновления рынка',
    DELETE_MARKET_ERROR: 'Ошибка удаления рынка',
    GER_NEWS_ERROR: 'Ошибка получения новостей',
    NEWS_CREATE_ERROR: 'Ошибка создания новости',
    BANNED: 'Заблокирован',
    RATE_VALIDATE_ERROR: 'Ошибка валидации курса',
    ORDER_CREATE_ERROR: 'Ошибка создания заявки',
    UPDATE_ORDER_ERROR: 'Ошибка обновления заявки',
    FEEDBACKS_GET_ERROR: 'Ошибка получения отзывов',
    WORKER_ALREADY_SET: 'Заявка уже принята в обработку',
    GER_ORDERS_ERROR: 'Ошибка получения заявок',
    GER_ORDER_ERROR: 'Ошибка получения заявки',
    DELETE_ORDER_ERROR: 'Ошибка удаления заявки',
    GER_PAGES_ERROR: 'Ошибка получения страниц',
    PAGE_CREATE_ERROR: 'Ошибка создания страницы',
    PAGE_UPDATE_ERROR: 'Ошибка обновления страницы',
    PAGE_DELETE_ERROR: 'Ошибка удаления страницы',
    PASSWORD_UPDATE_ERROR: 'Ошибка обновления пароля',
    BEARER_ERROR: 'Ошибка аутентификации',
    PASSWORD_DIFFERENT_ERROR: 'Пароль не подходит',
    DELETE_PARTNER_ERROR: 'Ошибка удаления партнёра',
    REDIRECT_ADD_ERROR: 'Ошибка редиректа',
    DUPLICATE_EMAIL_FOUND: 'Такой пользователь уже существует',
    AMOUNT_ERROR: 'Ошибка расчёта',
    REF_WITHDRAW_ERROR: 'Ошибка вывода',
    USER_UPDATE_ERROR: 'Ошибка обновления пользователя',
    USER_DELETE_ERROR: 'Ошибка удаления пользователя',
  },
  en: {
    UNKNOWN_ERROR: 'Unknown error',
    IMAGE_UPDATED: 'Image uploaded',
    ACCOUNT_DELETED: 'Account deleted',
    ACCOUNT_UPDATED: 'Account updated',
    PASSWORD_UPDATED: 'Password updated',
    SUCCESS_LOGIN: 'Successful login',
    ORDER_GET_ERROR: 'Order retrieval error',
    ORDER_CANCELED_SUCCESS: 'Order canceled successfully',
    ORDER_CANCELED_ERROR: 'Order cancellation error',
    ORDER_UPDATE_ERROR: 'Order update error',
    ORDER_UPDATE_SUCCESS: 'Order updated successfully',
    SUCCESS_SIGNUP: 'Successful signup',
    COPIED: 'Copied',
    NEEDWORKER: 'Need to accept the order',
    RESET_PASSWORD_ERROR: 'Password reset error',
    CREATE_CARD_ERROR: 'Card creation error',
    UPDATE_CARD_ERROR: 'Card update error',
    DELETE_CARD_ERROR: 'Card deletion error',
    CREATE_COIN_ERROR: 'Coin creation error',
    UPDATE_COIN_ERROR: 'Coin update error',
    DELETE_COIN_ERROR: 'Coin deletion error',
    GER_COMPONENTS_ERROR: 'Components retrieval error',
    COMPONENT_CREATE_ERROR: 'Component creation error',
    COMPONENT_UPDATE_ERROR: 'Component update error',
    COMPONENT_DELETE_ERROR: 'Component deletion error',
    CREATE_MARKET_ERROR: 'Market creation error',
    UPDATE_MARKET_ERROR: 'Market update error',
    DELETE_MARKET_ERROR: 'Market deletion error',
    GER_NEWS_ERROR: 'News retrieval error',
    NEWS_CREATE_ERROR: 'News creation error',
    BANNED: 'Banned',
    RATE_VALIDATE_ERROR: 'Rate validation error',
    ORDER_CREATE_ERROR: 'Order creation error',
    UPDATE_ORDER_ERROR: 'Order update error',
    FEEDBACKS_GET_ERROR: 'Feedbacks retrieval error',
    WORKER_ALREADY_SET: 'Order already taken in processing',
    GER_ORDERS_ERROR: 'Orders retrieval error',
    GER_ORDER_ERROR: 'Order retrieval error',
    DELETE_ORDER_ERROR: 'Order deletion error',
    GER_PAGES_ERROR: 'Pages retrieval error',
    PAGE_CREATE_ERROR: 'Page creation error',
    PAGE_UPDATE_ERROR: 'Page update error',
    PAGE_DELETE_ERROR: 'Page deletion error',
    PASSWORD_UPDATE_ERROR: 'Password update error',
    BEARER_ERROR: 'Authentication error',
    PASSWORD_DIFFERENT_ERROR: 'Password mismatch',
    DELETE_PARTNER_ERROR: 'Partner deletion error',
    REDIRECT_ADD_ERROR: 'Redirect error',
    DUPLICATE_EMAIL_FOUND: 'User already exists',
    AMOUNT_ERROR: 'Calculation error',
    REF_WITHDRAW_ERROR: 'Withdrawal error',
    USER_UPDATE_ERROR: 'User update error',
    USER_DELETE_ERROR: 'User deletion error',
  }
};

function getLanguageFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
  return match ? match[2] : 'ru'; // Если cookie не найдены, по умолчанию используем 'ru'
}

// Установим локаль на основе cookie
const language = getLanguageFromCookie();
Vue.prototype.$messages = messages[language];

// Vue.prototype.$messages = messages;

export default messages;