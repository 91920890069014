<template>
  <div
    v-loading="loading"
    element-loading-text="Загружаем данные"
    element-loading-background="white"
  >
    <Header
      :isAuthenticated="isAuthenticated"
      :isAdmin="isAdmin"
      :isMonitoring="isMonitoring"
      @logOut="logOutUser"
    />
    <div
      class="layout__content"
    >
      <slot />
    </div>
    <Footer />
    <div
      v-if="cookie"
      class="cookieMessage"
    >
      <div class="text">{{ $dictionary.cookieTitle }}</div>
      <el-button
        @click="cookieAgree"
      >{{$dictionary.okay}}</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'LayoutDefault',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      loading: true,
      cookie: false
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    },
    isAdmin () {
      return this.$store.state.auth.user?.roles.includes('ADMIN')
    },
    isMonitoring () {
      return this.$store.state.auth.user?.roles.includes('MONITORING')
    }
  },
  methods: {
    cookieAgree () {
      this.cookie = false
      window.localStorage.setItem('cookieAgree', true)
    },
    logOutUser () {
      if (this.$route.name === 'page-account' || this.$route.name === 'page-account-monitoring' || this.$route.name.includes('admin')) this.$router.push({ name: 'page-login'})
      this.logOut()
    },
    async getCoins () {
      try { 
        await this.fetchCoins()
      } catch (error) { console.log(error) }
    },
    async maintenance () {
      try {
        const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
        const globalParams = await this.globalParams(match ? match[2] : 'ru')
        if (globalParams.maintenance && !this.isAdmin) this.$router.push({ name: 'page-maintenance'})
      } catch (error) { console.log(error) }
    },
    async fetchData () {
      if (localStorage.getItem('accessToken')) {
        try { 
          await this.getUser()
        } catch (error) {
          try {
            await this.getPartner()
          } catch (error) {
            this.logOut()
          }
        }
      }
    },
    async loadScript (src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
        document.body.appendChild(script)
      });
    },
    async loadAllScripts () {
      try {
        await this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js')
        await this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.1.9/p5.min.js')
        await this.loadScript(this.$store.state.global.components.common.content.vantaJsLink)
      } catch (error) {
        console.error('Error loading scripts:', error)
      }
    },
    async initApp () {
      await Promise.all([this.getCoins(), this.fetchData(), this.maintenance()])
      if (this.$store.state.global.components.common.content.vantaJsConfig && this.$store.state.global.components.common.content.vantaJsLink) {
        try {
          await this.loadAllScripts()
          eval(this.$store.state.global.components.common.content.vantaJsConfig)
        } catch (error) {
          console.log(error, 'Ошибка инициализации VantaJS')
        }
      }
      if (this.$store.state.global.components.common.content.jivoChatLink) {
        try {
          let jivoChat = document.createElement('script')
          jivoChat.setAttribute('src', this.$store.state.global.components.common.content.jivoChatLink)
          document.body.appendChild(jivoChat)
        } catch (error) {
          console.log(error, 'Ошибка инициализации JivoChat')
        }
      }
      if (this.$store.state.global.components.common.content.yandexMetrika && this.$store.state.global.components.common.content.yandexMetrikaLink) {
        try {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.innerHTML = this.$store.state.global.components.common.content.yandexMetrika
          document.body.appendChild(script)
          const noscriptDiv = document.createElement('div')
          const noscriptImg = document.createElement('img')
          noscriptImg.src = this.$store.state.global.components.common.content.yandexMetrikaLink
          noscriptImg.style.position = "absolute"
          noscriptImg.style.left = "-9999px"
          noscriptImg.alt = ""
          noscriptDiv.appendChild(noscriptImg)
          document.body.appendChild(noscriptDiv)
        } catch (error) {
          console.log(error, 'Ошибка инициализации Yandex метрики')
        }
      }
      if (this.$store.state.global.components.common.content.customCss) {
        try {
          const style = document.createElement('style')
          style.textContent = this.$store.state.global.components.common.content.customCss
          document.head.appendChild(style)
        } catch (error) {
          console.log(error, 'Ошибка инициализации кастомных стилей')
        }
      }
      this.loading = false
      try {
        const redirectTime = JSON.parse(window.localStorage.getItem('redirectSet'))
        if (!redirectTime || redirectTime + 3600000 < Date.now()) {
          const result = await this.setRedirect({
            referredFor: (new URLSearchParams(window.location.search)).get('rid')
          })
          if (result) window.localStorage.setItem('redirectSet', Date.now())
        }
      } catch (error) { console.log(error) }
      if (JSON.parse(window.localStorage.getItem('cookieAgree')) === true) {
        this.cookie = false 
      } else {
        this.cookie = true
      }
    },
    ...mapActions([
      'getPartner',
      'getUser',
      'fetchCoins',
      'setRedirect',
      'globalParams'
    ]),
    ...mapMutations([
      'logOut'
    ])
  },
  created () {
    this.initApp()
  },
  // mounted () {
  //   // window.onbeforeunload = () => (
  //   //   window.localStorage.removeItem('referredFor')
  //   // );
  // }
}
</script>
