<template>
  <div class="header">

    <div class="header__items">
      <router-link
        tag="a"
        :to="{ name: 'page-main' }"
      >
        <img
          :src="header?.content?.logoLink"
          alt="Logo"
          class="header__logo"
        >
      </router-link>
    </div>

    <div class="header__items desktop">
      <router-link
        tag="a"
        :to="{ name: 'page-main' }"
        class="header__link"
      >
        {{ $dictionary.exchange }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-feedback' }"
        class="header__link"
      >
        {{ $dictionary.feedback }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-services' }"
        class="header__link"
      >
        {{ $dictionary.services }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-news' }"
        class="header__link"
      >
        {{ $dictionary.news }}
      </router-link>

      <el-dropdown>
        <span class="header__link">
          {{ $dictionary.offers }}
        </span>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-rules' }"
              class="header__link"
            >
              {{ $dictionary.rules }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-aml' }"
              class="header__link"
            >
              {{ $dictionary.aml }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-contact' }"
              class="header__link"
            >
              {{ $dictionary.contact }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-partner' }"
              class="header__link"
            >
              {{ $dictionary.partnerProgram }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-rates' }"
              class="header__link"
            >
              {{ $dictionary.rates }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-faq' }"
              class="header__link"
            >
              {{ $dictionary.faq }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              tag="a"
              :to="{ name: 'page-chart' }"
              class="header__link"
            >
              {{ $dictionary.chart }}
            </router-link>
          </el-dropdown-item>
          
        </el-dropdown-menu>
      </el-dropdown>

    </div>

    <div class="header__items desktop">
      <el-dropdown class="lang">
        <div class="header__flag">
          <img
            :src="`/langLogo/${lang}.png`"
            alt="lang logo"
          >
        </div>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item
            v-for="(lang, i) in langOptions"
            :key="i"
          >
            <div
              class="header__flag"
              @click="setLang(lang)"
            >
              <img
                :src="`/langLogo/${lang}.png`"
                alt="lang logo"
              >
            </div>
          </el-dropdown-item>
          
        </el-dropdown-menu>
      </el-dropdown>

      <router-link
        v-if="!isAuthenticated"
        tag="a"
        :to="{ name: 'page-login' }"
        class="header__link"
      >
        {{ $dictionary.login }}
      </router-link>

      <el-dropdown v-if="isAuthenticated">
        <span class="header__link">
          {{ $dictionary.account }}
        </span>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item>
            <router-link
              v-if="isAuthenticated"
              tag="a"
              :to="isMonitoring ? { name: 'page-account-monitoring' } : { name: 'page-account' }"
              class="header__link"
            >
              {{ $dictionary.account }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
            divided
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-rates' }"
              class="header__link"
            >
              {{ $dictionary.rates }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-cards' }"
              class="header__link"
            >
              {{ $dictionary.cards }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-markets' }"
              class="header__link"
            >
              {{ $dictionary.markets }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-orders' }"
              class="header__link"
            >
              {{ $dictionary.orders }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-wallets' }"
              class="header__link"
            >
              {{ $dictionary.wallets }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-partners' }"
              class="header__link"
            >
              {{ $dictionary.partners }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-redirects' }"
              class="header__link"
            >
              {{ $dictionary.redirects }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-statistics' }"
              class="header__link"
            >
              {{ $dictionary.statistics }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-users' }"
              class="header__link"
            >
              {{ $dictionary.users }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-pages' }"
              class="header__link"
            >
              {{ $dictionary.pages }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="isAuthenticated && isAdmin"
          >
            <router-link
              tag="a"
              :to="{ name: 'page-admin-bans' }"
              class="header__link"
            >
              {{ $dictionary.blockLists }}
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item divided>
            <div
              v-if="isAuthenticated"
              @click="$emit('logOut')"
              class="header__link"
            >
              {{ $dictionary.logout }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <nav role="navigation" class="header__items mobile">
      <div id="menuToggle">
        <input
          ref="toggle"
          type="checkbox"
        />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <div style="display: flex; flex-direction: column;">
            <div class="header__link"  style="display: flex; justify-content: space-between;">
              <router-link
                tag="a"
                :to="{ name: 'page-main' }"
              >
                {{ $dictionary.exchange }}
              </router-link>
              <el-dropdown class="lang">
                <div class="header__flag">
                  <img
                    :src="`/langLogo/${lang}.png`"
                    alt="lang logo"
                  >
                </div>
                <el-dropdown-menu slot="dropdown">

                  <el-dropdown-item
                    v-for="(lang, i) in langOptions"
                    :key="i"
                  >
                    <div
                      class="header__flag"
                      @click="setLang(lang)"
                    >
                      <img
                        :src="`/langLogo/${lang}.png`"
                        alt="lang logo"
                      >
                    </div>
                  </el-dropdown-item>
                  
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <router-link
              tag="a"
              :to="{ name: 'page-rates' }"
              class="header__link"
            >
              {{ $dictionary.rates }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-rules' }"
              class="header__link"
            >
              {{ $dictionary.rules }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-aml' }"
              class="header__link"
            >
              {{ $dictionary.aml }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-contact' }"
              class="header__link"
            >
              {{ $dictionary.contact }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-feedback' }"
              class="header__link"
            >
              {{ $dictionary.feedback }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-news' }"
              class="header__link"
            >
              {{ $dictionary.news }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-chart' }"
              class="header__link"
            >
              {{ $dictionary.charts }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-faq' }"
              class="header__link"
            >
              {{ $dictionary.faq }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-partner' }"
              class="header__link"
            >
              {{ $dictionary.partnerProgram }}
            </router-link>
            <router-link
              v-if="!isAuthenticated"
              tag="a"
              :to="{ name: 'page-login' }"
              class="header__link"
            >
              {{ $dictionary.login }}
            </router-link>
            <router-link
              v-if="isAuthenticated"
              tag="a"
              :to="isMonitoring? { name: 'page-account-monitoring' } : { name: 'page-account' }"
              class="header__link"
            >
              {{ $dictionary.account }}
            </router-link>

            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-rates' }"
              class="header__link"
              style="border-top: 2px solid grey"
            >
              {{ $dictionary.rates }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-cards' }"
              class="header__link"
            >
              {{ $dictionary.cards }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-orders' }"
              class="header__link"
            >
              {{ $dictionary.orders }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-markets' }"
              class="header__link"
            >
              {{ $dictionary.markets }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-wallets' }"
              class="header__link"
            >
              {{ $dictionary.wallets }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-partners' }"
              class="header__link"
            >
              {{ $dictionary.partners }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-redirects' }"
              class="header__link"
            >
              {{ $dictionary.redirects }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-pages' }"
              class="header__link"
            >
              {{ $dictionary.pages }}
            </router-link>
            <router-link
            v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-statistics' }"
              class="header__link"
            >
              {{ $dictionary.statistics }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-users' }"
              class="header__link"
            >
              {{ $dictionary.users }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-bans' }"
              class="header__link"
              style="border-bottom: 2px solid grey"
            >
              {{ $dictionary.blockLists }}
            </router-link>

            <div
              v-if="isAuthenticated"
              @click="$emit('logOut')"
              class="header__link"
            >
              {{ $dictionary.logout }}
            </div>
          </div>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isMonitoring: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    header () {
      return this.$store.state.global.components.header
    },
    lang () {
      const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'))
      return match ? match[2] : 'ru'
    },
    langOptions () {
      return ['ru', 'en'].filter(lang => lang !== this.lang)
    }
  },
  watch: {
    $route () {
      this.$refs.toggle.checked = false
    }
  },
  methods: {
    setLang (lang) {
      document.cookie = `language=${lang};path=/`;
      window.location.reload();
    }
  }
}
</script>
